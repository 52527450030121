import React from 'react';
import styles from './TitleBar.module.css';

const TitleBar = ({ id, children }) => (
  <div className={styles.titleContainer}>
    <h3 className={styles.title} id={id || undefined}>
      {children}
    </h3>
  </div>
);

export default TitleBar;
