import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { FaFacebookSquare, FaRegWindowMaximize } from 'react-icons/fa';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import styles from '../../css/aboutus.module.css';
import PageHeading from '../../components/PageHeading/PageHeading';
import TitleBar from '../../components/TitleBar/TitleBar';
import '../../css/toggleComponent.css';
import '../../css/map.css';
// import '../../css/leaugeofchaptersmodal.css';
import states from '../../components/LienDoanMap/stateMap.json';

const LogoImg = props => {
  const imgStyle = {
    maxWidth: '175px',
    // maxHeight: '150px',
    // borderRadius: '75px',
  };

  return (
    <StaticQuery
      query={graphql`
        query LogoImageQuery {
          NoLDLogo: file(relativePath: { eq: "LDLogos/NoLDLogo.png" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDJP2: file(relativePath: { eq: "LDLogos/LDJP2.png" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDI: file(relativePath: { eq: "LDLogos/LDI.jpg" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDNS: file(relativePath: { eq: "LDLogos/LDNS.jpg" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDSD: file(relativePath: { eq: "LDLogos/LDSD.png" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDRK: file(relativePath: { eq: "LDLogos/LDRK.png" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDJoA: file(relativePath: { eq: "LDLogos/LDJoA.png" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDBD: file(relativePath: { eq: "LDLogos/LDBD.jpg" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDDS: file(relativePath: { eq: "LDLogos/LDDS.jpg" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDPH: file(relativePath: { eq: "LDLogos/LDPH.jpg" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDSN: file(relativePath: { eq: "LDLogos/LDSN.jpg" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          LDTG: file(relativePath: { eq: "LDLogos/LDTG.png" }) {
            childImageSharp {
              fixed(width: 175, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <Img
          fixed={data[props.name].childImageSharp.fixed}
          style={{}}
          imgStyle={imgStyle}
        />
      )}
    />
  );
};

const LeaguesOfChapters = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState(null);

  const toggle = () => setModalOpen(!modalOpen);

  const fullLDName = ld => {
    switch (ld) {
      case 'LDBD':
        return 'Liên Đoàn Biển Đức';
      case 'LDPH':
        return 'Liên Đoàn Phaolô Hạnh';
      case 'LDDS':
        return 'Liên Đoàn Đaminh Saviô';
      case 'LDJP2':
        return 'Liên Đoàn John Paul II';
      case 'LDJoA':
        return 'Liên Đoàn Joan of Arc';
      case 'LDI':
        return 'Liên Đoàn Ignatius Loyola';
      case 'LDRK':
        return 'Liên Đoàn Ra Khơi';
      case 'LDS':
        return 'Liên Đoàn Sinai';
      case 'LDTG':
        return 'Liên Đoàn Holy Family';
      case 'LDNS':
        return 'Liên Đoàn Nguồn Sống';
      case 'LDSD':
        return 'Lien Doan San Diego';
      case 'LDSN':
        return 'Lien Doan Sinai';
      default:
        return 'Other';
    }
  };

  return (
    <Layout>
      <Helmet
        title="VEYM | Leagues of Chapters"
        meta={[
          {
            name: 'description',
            content: `Leagues of Chapters (Liên Đoàn) in VEYM-USA`,
          },
        ]}
      />
      <PageHeading>Leagues of Chapters</PageHeading>

      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/aboutus">About</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Leagues of Chapters</BreadcrumbItem>
        </Breadcrumb>
      </Container>

      <br />

      <Modal isOpen={modalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody className={styles.modalBody}>{modalBody}</ModalBody>
      </Modal>

      <Container>
        <div className={styles.mapdiv}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1010 593">
            {states.map(({ league, d, logo, FBLink, WebsiteLink }) => (
              <g
                key={league}
                className={styles[league]}
                onClick={() => {
                  setModalTitle(fullLDName(league));

                  setModalBody(
                    <div>
                      <br />
                      <Container>
                        <Row>
                          <Col xs={12} sm={6} className={styles.imageContainer}>
                            <LogoImg name={logo} />
                          </Col>
                          <Col xs={12} sm={6} className={styles.linksContainer}>
                            <Row className={styles.linkText}>
                              <a
                                href={FBLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="facebook"
                              >
                                <FaFacebookSquare
                                  className={`${styles.fbIcon} ${styles.socialIcon}`}
                                  size="2.5em"
                                />
                                <b>Facebook</b>
                              </a>
                            </Row>

                            <Row className={styles.linkText}>
                              <a
                                href={WebsiteLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="website"
                              >
                                <FaRegWindowMaximize
                                  className={`${styles.webIcon} ${styles.socialIcon}`}
                                  size="2.5em"
                                />
                                <b>Website </b>
                              </a>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  );
                  setModalOpen(true);
                }}
              >
                <path d={d} />
                <title>{fullLDName(league)}</title>
              </g>
            ))}
          </svg>
        </div>
      </Container>

      <br />
      <br />

      <TitleBar>Leagues of Chapters</TitleBar>
      <br />
      <Container>
        <p>
          The Vietnamese Eucharistic Youth Movement in the USA consists of 11
          Leagues of Chapters.
        </p>
        <br />
        <Row>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Phaolô Hạnh</h4>
            <ul>
              <li>Connecticut</li>
              <li>Maine</li>
              <li>Massachusetts</li>
              <li>New Hampshire</li>
              <li>New Jersey</li>
              <li>New York</li>
              <li>Rhode Island</li>
              <li>Vermont</li>
            </ul>
            <br />
          </Col>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Đaminh Saviô</h4>
            <ul>
              <li>Delaware</li>
              <li>Maryland</li>
              <li>Pennsylvania</li>
              <li>Virginia</li>
              <li>Washington D.C.</li>
              <li>West Virginia</li>
            </ul>
            <br />
          </Col>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn John Paul II</h4>
            <ul>
              <li>Colorado</li>
              <li>Illinois</li>
              <li>Indiana</li>
              <li>Iowa</li>
              <li>Kansas</li>
              <li>Kentucky</li>
              <li>Michigan</li>
              <li>Minnesota</li>
              <li>Missouri</li>
              <li>Nebraska</li>
              <li>Ohio</li>
              <li>Wisconsin</li>
            </ul>
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Joan of Arc</h4>
            <ul>
              <li>Alabama</li>
              <li>Florida</li>
              <li>Georgia</li>
              <li>Louisiana</li>
              <li>Mississippi</li>
              <li>North Carolina</li>
              <li>South Carolina</li>
              <li>Tennessee</li>
            </ul>
            <br />
          </Col>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Biển Đức</h4>
            <ul>
              <li>Arkansas</li>
              <li>Louisiana</li>
              <li>New Mexico</li>
              <li>Oklahoma</li>
              <li>Texas</li>
            </ul>
            <br />
          </Col>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Ignatius Loyola</h4>
            <ul>
              <li>Alaska</li>
              <li>Idaho</li>
              <li>Montana</li>
              <li>North Dakota</li>
              <li>Oregon</li>
              <li>South Dakota</li>
              <li>Wyoming</li>
              <li>Utah</li>
              <li>Washington State</li>
            </ul>
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Holy Family</h4>
            <ul>
              <li>Bakersfield, California</li>
              <li>Fresno, California</li>
              <li>Oakland, California</li>
              <li>San Jose, California</li>
              <li>Sacramento, California</li>
              <li>San Francisco, California</li>
              <li>Hawaii</li>
            </ul>
            <br />
          </Col>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Nguồn Sống</h4>
            <ul>
              <li>Orange County, California</li>
            </ul>
            <br />
          </Col>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Ra Khơi</h4>
            <ul>
              <li>Los Angeles, California</li>
              <li>Las Vegas, Nevada</li>
            </ul>
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn San Diego</h4>
            <ul>
              <li>San Diego, California</li>
            </ul>
            <br />
          </Col>
          <Col sm={12} md={4}>
            <h4>Liên Đoàn Sinai</h4>
            <ul>
              <li>Arizona</li>
              <li>Riverside, California</li>
              <li>San Bernadino, California</li>
            </ul>
            <br />
          </Col>
        </Row>

        <br />

        <br />
      </Container>
    </Layout>
  );
};

export default LeaguesOfChapters;

export const frontmatter = {
  title: 'Leagues of Chapters',
  category: 'pages',
  path: '/aboutus/liendoan',
  description: 'Leagues of Chapters',
  tags:
    'about us, veym, tntt, thieu nhi thanh the, vietnamese eucharistic youth movement,lien doan, league of chapters, mien ',
};
